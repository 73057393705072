import './App.css';
import APIButton from "./APIButton";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Microservice Headerparser API

        </p>
      </header>

      <body>
        <APIButton />
      </body>
    </div>
  );
}

export default App;
